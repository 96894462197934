.h3-title {
  font-size: 24px;
  font-weight: 700;
}

.globalLabel {
  font-weight: 500;
  font-size: 16px;
  color: $hive-black;
  margin-bottom: 10px;
}

.globalBoldLabel {
  font-weight: 700;
}
