$hive-primary-blue: #567ae8;
$hive-secondary-blue: #40a9ff;
$hive-dark-blue: #15143c;
$hive-danger-blue: #1380e633;
$hive-loader-blue: #00bfff;
$hive-selected-pill-blue: #99dfff;
$hive-select-blue: #2684ff;
$hive-light-select-blue: #3d94ff;
$hive-sidemenu-item-hover: #3f96e9;
$hive-attachment-background: #edf1fd;
$hive-dirty-white: #f4f4f4;

$hive-gray: #697377;
$hive-light-gray: #f2f2f6;
$hive-darker-gray: #ccccd4;
$hive-medium-gray: #ebebf1;
$hive-grey-white: #fcf4fb;
$hive-disabled-background: #dde3e6;
$hive-disabled-color: #a2acb0;
$hive-header-color: #e2e8ed;
$hive-category-background-color: #f2f6f8;
$hive-default-grey: #eeeeee;
$hive-pill-background: #e9ecf0;
$hive-selection-gray: #888888;
$hive-dirty-white: #f7f7f9;

$hive-black: #000000;
$hive-white: #ffffff;
$hive-green: #4a9c97;
$hive-orange: #f08f65;
$hive-yellow: #edc14f;
$hive-light-blue: #61ceff;
$hive-grey-black: #21272a;
$hive-dark-grey: #121719;
$hive-dark-letter: #0f1529;
$hive-white-silver: #d7d7e0;
$hive-message-background: #ebf0ff;
$hive-info-text: #98a4ad;
$hive-grey-select: #c4c4c4;
$hive-grey-text: #202324;
$hive-collapse-content: #eff7fa;
$hive-carousel-dot: #e0eff5;
$hive-carousel-dot-selected: #cecedf;
$hive-carousel-dot-blue: #5579e4;
$hive-drawer-background: #f3f3f7;
$hive-who-wrapper-span: #4d6780;
$hive-invite-only-text: #5f6374;
$hive-white-background: #fdfdfd;
$hive-backdrop-background: #3e4c57;
$hive-selected-community-background: #d0dbfb;

$hive-intrerest-grey: #ffffff66;
$hive-search-grey: #ffffff8c;
$hive-pure-grey: #808080;
$hive-grey-name: #879196;
$hive-stepper-grey: #5e6770;

$hive-danger-red: #ee5552;
$hive-danger-red-hover: #ff7875;
$hive-pure-red: #ff0000;
$hive-single-page-top-bar: #e6ecef;

$hive-new-orange: #f7941d;
$hive-new-blue: #00b3c5;

$hive-neutral: #c1c9cd;
