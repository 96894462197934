@import "./global";

.tabTitle {
  @extend .h3-title;
}

.eventsContainer {
  position: relative;
  display: flex;
  // flex-wrap: wrap;
  overflow: auto;
}
