@font-face {
  font-family: "EudoxusSans-Light";
  src: url("./../assets/fonts/EudoxusSans-Light.woff");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "EudoxusSans-Regular";
  src: url("./../assets/fonts/EudoxusSans-Regular.woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "EudoxusSans-Medium";
  src: url("./../assets/fonts/EudoxusSans-Medium.woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "EudoxusSans-Bold";
  src: url("./../assets/fonts/EudoxusSans-Bold.woff");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
