h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.h_1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 54px;
  margin: 0;
  letter-spacing: -0.5px;
}

.h_2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  margin: 0;
  letter-spacing: -0.5px;
}

.h_3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  letter-spacing: -0.5px;
}

.h_4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.h_5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

.h_6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin: 0;
  letter-spacing: -0.5px;
}

.h_7 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  letter-spacing: -0.5px;
}

.h_8 {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  margin: 0;
  letter-spacing: -0.5px;
}

.link {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
}
